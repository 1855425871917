<template>
<div class="classes">
  <h1 class="title grey--text">学员信息</h1>
  <v-container class="my-5">
    <v-card flat class="mb-5" v-if="studentInfo.length">
      <v-layout align-center justify-center row wrap class="pa-4 student-details">
        <v-flex xs4 md2>
          <div class="caption grey--text">学员称呼</div>
          <div class="caption">{{ studentInfo[0].student_name }}</div>
        </v-flex>
        <v-flex xs4 md2>
          <div class="caption grey--text">统计项目</div>
          <div class="caption">{{ studentInfo[0].program }}</div>
        </v-flex>
        <v-flex xs4 md2>
          <div class="caption grey--text">项目教师</div>
          <div class="caption">{{ studentInfo[0].tutor_program }}</div>
        </v-flex>
        <v-flex xs4 md2>
          <div class="caption grey--text">所属机构</div>
          <div class="caption">{{ studentInfo[0].organization }}</div>
        </v-flex>
        <v-flex xs4 md2>
          <div class="caption grey--text">所剩课时</div>
          <div class="caption">{{ studentInfo[0].lesson_quantity }} {{ studentInfo[0].unfinalizedLessonUsageQuantity > 0 ? `(含未结课时 ${studentInfo[0].unfinalizedLessonUsageQuantity})` : ''}}</div>
        </v-flex>
        <v-flex xs4 md2>
          <div class="caption grey--text">学员状态</div>
          <div class="caption">{{ studentInfo[0].enrollment_status }}</div>
        </v-flex>
      </v-layout>
    </v-card>

    <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">类别</th>
                <th class="text-left">课时</th>
                <th class="text-left">所使用课时包剩余课时</th>
                <th class="text-left">性质</th>
                <th class="text-left">活动名称</th>
                <th class="text-left">活动时间</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in studentLessonRecords" :key="item.lesson_record_id">
                <td class="caption">{{ item.category }}</td>
                <td class="caption">{{ item.quantity_change }}</td>
                <td class="caption">{{ item.remain_quantity }}</td>
                <td class="caption">{{ item.package_category }}</td>
                <td class="caption">{{ item.lesson }}</td>
                <td class="caption">{{ item.lesson_start_time }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
  </v-container>
  <!-- display overlay on the whole page while loading -->
  <v-overlay :value="$store.state.queryingState">
    <v-progress-circular
      indeterminate
      color=""
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import ProgramService from '@/services/ProgramService'
import moment from 'moment'
export default {
  components: {
  },
  data () {
    return {
      studentId: null,
      programId: null,
      studentInfo: [],
      studentLessonRecords: [],
      selected: []
    }
  },
  computed: {
  },
  async mounted () {
    this.studentId = this.$store.state.route.params.studentId
    this.programId = this.$store.state.route.params.programId
    /**
     * Retrieve studentInfo by student id and program id
     */
    this.studentInfo = await this.getStudentById(this.studentId, this.programId)
    /**
     * Retrieve student lesson history for current program
     */
    this.studentLessonRecords = await this.getStudentLessonRecords(this.studentId, this.programId)
    this.studentLessonRecords.forEach(studentLessonRecord => {
      studentLessonRecord.lesson_start_time = moment(studentLessonRecord.lesson_start_time).format('YYYY-MM-DD(dddd) HH:mm')
    })
  },
  methods: {
    async getStudentById (studentId, programId) {
      console.log('\n#getStudentById starts ... ')
      console.log('studentId: ', studentId, 'programId: ', programId)
      const res = await ProgramService.getStudents({ studentIds: [studentId], programIds: [programId], attachProgramInfo: true })
      console.log('retrieve studentInfo successful! \nstudentInfo: ', res.data)
      return res.data
    },
    async getStudentLessonRecords (studentId, programId, limit, offset) {
      console.log('\n#getStudentLessonRecords starts ... ')
      console.log('studentId: ', studentId, 'programId: ', programId, 'limit:', limit, 'offset: ', offset)
      const res = await ProgramService.getStudentLessonRecords(studentId, programId, limit, offset)
      console.log('retrieve studentLessonRecords successful! \nstudentLessonRecords: ', res.data)
      return res.data
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
